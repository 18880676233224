var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"project__header"},[_c('h1',{staticClass:"project__title"},[_vm._v(_vm._s(_vm.project.attributes.project_name))]),_c('span',{staticClass:"h3"},[_vm._v(_vm._s(_vm.formatDurationString(_vm.projectTimeByDay(_vm.project.id))))])]),_c('at-collapse',{staticClass:"list__item",attrs:{"simple":""}},_vm._l((_vm.project.attributes.users),function(user){return _c('at-collapse-item',{key:user.id,staticClass:"list__item"},[_c('div',{staticClass:"row flex-middle",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"col-3 col-xs-2 col-md-1"},[_c('user-avatar',{attrs:{"user":user.attributes,"size":_vm.avatarSize}})],1),_c('div',{staticClass:"col-8 col-md-10 col-lg-11"},[_c('span',{staticClass:"h5"},[_vm._v(_vm._s(`${user.attributes.first_name} ${user.attributes.last_name}`))])]),_c('div',{staticClass:"col-4 col-md-3 col-lg-2"},[_c('span',{staticClass:"h4"},[_vm._v(_vm._s(_vm.formatDurationString(_vm.userTimeByDay(user.id, _vm.project.id))))])]),_c('div',{staticClass:"col-10"},[_c('at-progress',{attrs:{"status":"success","stroke-width":15,"percent":_vm.getUserPercentage(
                                _vm.userTimeByDay(user.id, _vm.project.id),
                                _vm.project.attributes.project_total_time,
                            )}})],1)]),_c('at-collapse',{attrs:{"simple":"","accordion":""},on:{"on-change":function($event){return _vm.handleCollapseTask(user, $event)}}},_vm._l((_vm.filterTasks(user.id)),function(task){return _c('at-collapse-item',{key:`tasks-${task.id}`,attrs:{"name":`${task.id}`}},[_c('div',{staticClass:"row",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"col-10 col-md-11 col-lg-12"},[_c('span',{staticClass:"h4"},[_vm._v(_vm._s(task.attributes.task_name))])]),_c('div',{staticClass:"col-4 col-md-3 col-lg-2"},[_c('span',{staticClass:"h4"},[_vm._v(_vm._s(_vm.formatDurationString(_vm.taskTimeByDay(task.id, user.id))))])]),_c('div',{staticClass:"col-10"},[_c('at-progress',{attrs:{"status":"success","stroke-width":15,"percent":_vm.getUserPercentage(
                                        _vm.taskTimeByDay(task.id, user.id),
                                        _vm.project.attributes.project_time,
                                    )}})],1)])])}),1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }