<template>
    <div>
        <div class="project__header">
            <h1 class="project__title">{{ project.attributes.project_name }}</h1>
            <span class="h3">{{ formatDurationString(projectTimeByDay(project.id)) }}</span>
        </div>
        <at-collapse simple class="list__item">
            <at-collapse-item v-for="user in project.attributes.users" :key="user.id" class="list__item">
                <div slot="title" class="row flex-middle">
                    <div class="col-3 col-xs-2 col-md-1">
                        <user-avatar :user="user.attributes" :size="avatarSize" />
                    </div>
                    <div class="col-8 col-md-10 col-lg-11">
                        <span class="h5">{{ `${user.attributes.first_name} ${user.attributes.last_name}` }}</span>
                    </div>
                    <div class="col-4 col-md-3 col-lg-2">
                        <span class="h4">{{ formatDurationString(userTimeByDay(user.id, project.id)) }}</span>
                    </div>
                    <div class="col-10">
                        <at-progress
                            status="success"
                            :stroke-width="15"
                            :percent="
                                getUserPercentage(
                                    userTimeByDay(user.id, project.id),
                                    project.attributes.project_total_time,
                                )
                            "
                        />
                    </div>
                </div>

                <at-collapse simple accordion @on-change="handleCollapseTask(user, $event)">
                    <at-collapse-item
                        v-for="task in filterTasks(user.id)"
                        :key="`tasks-${task.id}`"
                        :name="`${task.id}`"
                    >
                        <div slot="title" class="row">
                            <div class="col-10 col-md-11 col-lg-12">
                                <span class="h4">{{ task.attributes.task_name }}</span>
                            </div>
                            <div class="col-4 col-md-3 col-lg-2">
                                <span class="h4">{{ formatDurationString(taskTimeByDay(task.id, user.id)) }}</span>
                            </div>
                            <div class="col-10">
                                <at-progress
                                    status="success"
                                    :stroke-width="15"
                                    :percent="
                                        getUserPercentage(
                                            taskTimeByDay(task.id, user.id),
                                            project.attributes.project_time,
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <!-- <at-collapse
                            v-if="checkAccess()"
                            class="project__screenshots screenshots"
                            accordion
                            @on-change="handleCollapseDate"
                        >
                            <span class="screenshots__title">{{ $t('field.screenshots') }}</span>
                            <at-collapse-item
                                v-for="(interval, key) of filterIntervals(user.id, task.id)"
                                :key="key"
                                :name="`${task.id}-${key}`"
                            >
                                <div slot="title" class="row">
                                    <div class="col-12">
                                        <span class="h5 screenshots__date">
                                            {{
                                                `Time ${moment(interval.attributes.created_at)
                                                    .locale($i18n.locale)
                                                    .format('HH:MM:SS')}`
                                            }}
                                        </span>
                                    </div>
                                    <div class="col-12">
                                        <span class="h5">{{ formatDurationString(interval.attributes.time) }}</span>
                                    </div>
                                </div>

                                <template v-if="isDateOpened(`${task.id}-${key}`)">
                                    <template>
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <Screenshot
                                                    v-if="interval"
                                                    :key="interval.id"
                                                    class="screenshots__item"
                                                    :interval="interval"
                                                    :user="user"
                                                    :task="task"
                                                    :disableModal="true"
                                                    :showNavigation="true"
                                                    :showTask="false"
                                                    @click="onShow(interval, user, task, project)"
                                                />

                                                <div
                                                    v-else
                                                    :key="index"
                                                    class="screenshots__item screenshots__placeholder"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </at-collapse-item>
                        </at-collapse> -->
                    </at-collapse-item>
                </at-collapse>
            </at-collapse-item>
        </at-collapse>

        <!-- <ScreenshotModal
            v-if="modal.interval"
            :show="modal.show"
            :interval="modal.interval"
            :project="project"
            :task="modal.task"
            :user="modal.user"
            :showNavigation="true"
            :canRemove="false"
            @close="onHide"
            @showPrevious="onShowPrevious"
            @showNext="onShowNext"
        /> -->
    </div>
</template>

<script>
    import moment from 'moment';
    // import { mapGetters } from 'vuex';
    // import Screenshot from '@/components/Screenshot';
    // import ScreenshotModal from '@/components/ScreenshotModal';
    import UserAvatar from '@/components/UserAvatar';
    import TimeIntervalService from '@/services/resource/time-interval.service';
    import { formatDurationString } from '@/utils/time';

    const intervalService = new TimeIntervalService();

    export default {
        name: 'ProjectLine',
        components: {
            // Screenshot,
            // ScreenshotModal,
            UserAvatar,
        },
        data() {
            return {
                modal: {
                    show: true,
                    intervals: {},
                    interval: null,
                    project: null,
                    user: null,
                    task: null,
                },
                openedDates: [],
                avatarSize: 35,
                taskDurations: {},
                screenshotsPerRow: 6,
            };
        },
        props: {
            project: {
                type: Object,
                required: true,
            },
            start: {
                type: String,
            },
            end: {
                type: String,
            },
            intervals: {
                type: Array,
                required: true,
            },
        },
        mounted() {
            window.addEventListener('keydown', this.onKeyDown);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.onKeyDown);
        },
        methods: {
            moment,
            formatDurationString,
            onShow(interval, user, task, project) {
                this.modal = {
                    ...this.modal,
                    show: true,
                    interval,
                    user,
                    task,
                    project,
                };
            },
            onHide() {
                this.modal.show = false;
            },
            filterIntervals(user_id, task_id) {
                return this.userIntervals(user_id).filter(obj => obj.attributes.task_id === task_id);
            },
            filterTasks(user_id) {
                return this.project.attributes.tasks.filter(task =>
                    task.attributes.user_ids.some(userID => userID === user_id),
                );
            },
            userIntervals(user_id) {
                return this.intervals.filter(interval => interval.attributes.user_id === user_id);
            },
            userTimeByDay(user_id, project_id) {
                const userIntervals = this.userIntervals(user_id).filter(
                    interval => interval.attributes.project.id === project_id,
                    0,
                );
                return userIntervals.reduce((sum, interval) => (sum += interval.attributes.time), 0);
            },
            projectTimeByDay(project_id) {
                const projectIntervals = this.intervals.filter(
                    interval => interval.attributes.project.id === project_id,
                );
                return projectIntervals.reduce((sum, interval) => (sum += interval.attributes.time), 0);
            },
            taskTimeByDay(task_id, user_id) {
                const taskIntervals = this.intervals.filter(
                    interval => interval.attributes.task_id === task_id && interval.attributes.user_id === user_id,
                );
                return taskIntervals.reduce((sum, interval) => (sum += interval.attributes.time), 0);
            },
            onKeyDown(e) {
                if (e.key === 'ArrowLeft') {
                    e.preventDefault();
                    this.onShowPrevious();
                } else if (e.key === 'ArrowRight') {
                    e.preventDefault();
                    this.onShowNext();
                }
            },
            onShowPrevious() {
                return;
            },
            onShowNext() {
                return;
            },
            isDateOpened(collapseId) {
                return this.openedDates.findIndex(p => p === collapseId) > -1;
            },
            handleCollapseDate(data) {
                this.openedDates = data;
            },
            handleCollapseTask(user, taskID) {
                if (typeof taskID === 'object') {
                    taskID = taskID[0];
                }
                const key = `${user.id}:${taskID}`;
                this.$set(this.taskDurations, key, user.tasks);
            },
            formatDate(value) {
                return moment(value).format('DD.MM.YYYY HH:mm:ss');
            },
            getUserPercentage(seconds, totalTime) {
                if (!totalTime) {
                    return 0;
                }

                return Math.floor((seconds * 100) / totalTime);
            },
            getHourRow(screenshots) {
                return new Array(this.screenshotsPerRow).fill(null).map((el, i) => screenshots[i] || el);
            },
            checkAccess() {
                const user = JSON.parse(localStorage.getItem('user'));
                return user.roles_access.is_admin || user.roles_access.is_reporter;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .project {
        color: #ffa500;
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: none;
            padding: 14px 21px;
            border-bottom: 3px solid $blue-3;

            .theme-dark & {
                border-color: #555;
            }
        }

        &__title {
            color: $black-900;
            font-size: 2rem;
            font-weight: bold;
            .theme-dark & {
                color: #ffa500;
            }
        }

        &__screenshots {
            margin-bottom: $spacing-05;
        }
    }

    .screenshots {
        padding-top: $spacing-03;

        &__title {
            font-size: 15px;
            color: $gray-3;
            font-weight: bold;
        }

        &__date {
            padding-left: 20px;
        }

        &__item {
            margin-bottom: $spacing-04;
        }

        &__placeholder {
            width: 100%;
            height: 150px;
            border: 2px dashed $gray-3;
        }

        &::v-deep {
            .at-collapse__header {
                padding: 14px 0;
            }

            img {
                object-fit: cover;
                height: 150px;
            }

            .at-collapse__icon {
                top: 20px;
                left: 0;

                color: black;
            }

            .at-collapse__icon.icon-chevron-right {
                display: block;
            }
        }
    }
</style>
